import { IAccessTokenData } from "adapters/auth";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";
import config from "./config";

export interface IPaginatedData<T>  {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export interface IErrorWithDetail extends AxiosError {
    response: AxiosResponse<{detail: string, status: number}>
}

const webAPIRequest = async<T>(
    method: Method,
    url: string,
    opts?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {

    const token = sessionStorage.getItem("accessToken");
    const tokenJson: IAccessTokenData | undefined = token ? JSON.parse(token) : undefined;    
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.withCredentials = true;
    const axiosConfig = {
        baseURL: config.apiURL,
        url,
        method,
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": "en",
            "X-CSRFTOKEN": csrftoken,
            "Authorization": tokenJson ? `Bearer ${tokenJson["access_token"]}` : ""  
        },
        ...opts,
    };
    return await axios.request(axiosConfig);
};


export default webAPIRequest;
